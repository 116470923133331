import {useContext} from "react";
import {StoreContext} from "../../stores/StoreLoader";
import useSWRImmutable from "swr/immutable";
import WatsonApi from "../../backends/WatsonApi";
import styles from "./styles/NotificationsIcon.module.scss";
import {observer} from "mobx-react";
import classNames from 'classnames';

export const NotificationsIcon = observer((props: {
    className?: string;
}) => {
    const {userStore} = useContext(StoreContext);

    useSWRImmutable("num-unread-activity", async () => {
        const client = await WatsonApi();
        const response = await client.apis.users.users_activity_num_unread({
            user_pk: userStore.id,
        })
        const count = JSON.parse(response.data);
        userStore.setUnreadNotificationsCount(count);
        return count; // useSWR must return something
    })

    const iconClassName = classNames({
        [styles.notificationIcon]: true,
        [props.className || ""]: props.className,
    })

    if (userStore.unreadNotificationsCount > 0) {
        const count = userStore.unreadNotificationsCount > 9 ? "9+" : userStore.unreadNotificationsCount;
        return <span className={iconClassName}>{count}</span>
    } else {
        return <span />
    }
})